/**
 * Created by frebault on 04/01/16.
 */

// to be fulfilled
$(document).ready(function () {
    $(".loader").hide();
    $(".loader-wrapper").hide();

    $(function () {
        $('[data-bs-toggle="tooltip"]').tooltip();
    });

    $(function () {
        $('[data-bs-toggle="popover"]').popover();
    });

    /***********************************************************************************************************************
    *
    *                                                  End Menu
    *
    ************************************************************************************************************************/

    /***********************************************************************************************************************
    *
    *                                                  change glyphicon in collapse
    *
    ************************************************************************************************************************/
    //change glyphicon on collapse
    toggleChevronAccordion();

    /***********************************************************************************************************************
    *
    *                                                  End change glyphicon
    *
    ************************************************************************************************************************/


    /***********************************************************************************************************************
    *
    *                                                 add tooltip when there is help in form
    *
    ************************************************************************************************************************/

    $(".help").find("*").each(function() {
        var attr = $(this).attr("help");
        if (typeof attr != typeof undefined && attr != false) {
            var title = $(this).parents(".help").children(".infoLabel").text();

            var $a = $('<a/>').attr({
                'tabindex': 0,
                'role': 'button',
                'data-bs-toggle': 'popover',
                'data-bs-trigger': 'focus',
                'title': title,
                'data-bs-content': attr,
                'data-bs-html': true,
                'data-bs-placement': $(this).attr('data-bs-placement')
                }).append($('<span/>').addClass('fa fa-info-circle text-primary'));

            $(this).parents(".help").children("label").append('&nbsp;&nbsp;').append($a);
        }
    });

    // $('body').prepend("<div id=\"background-image\"></div>");
});

// to be fulfilled
export function refreshHelp(form){
    $("#"+ form).find("*").each(function() {
        var attr= $(this).attr("help");

        if (typeof attr != typeof undefined && attr != false) {
            var title = $(this).parents(".help").children(".infoLabel").text();

            $(this).parents(".help").children("label").append('&nbsp;&nbsp;' +
                '<a tabindex="0" role="button" data-bs-toggle="popover" data-bs-trigger="focus" title="'+title+'" data-bs-content="'+attr+'" data-bs-html="true">' +
                    '<span class="fa fa-info-circle text-primary"></span>' +
                '</a>'
            );
        }


    });
}


/***********************************************************************************************************************
 *
 *                                                        validate textarea
 * unsupported pattern attribute on textarea element in HTML5 (ccbopa - 07/09/20)
 ***********************************************************************************************************************/
// to be fulfilled
export function validateTextarea(elt) {
    var errorMsg = $(elt).attr('title');
    var textarea = elt;
    var hasError = null;

    // check each line of text
    if($(textarea).attr('minlength') !== undefined){
        var length = parseInt($(textarea).attr('minlength'));
        hasError = ($(textarea).val().length < length);
    } else {
        var pattern = new RegExp('^' + $(textarea).attr('pattern') + '$');
        $.each($(elt).val().split("\n"), function () {
            // check if the line matches the pattern
            hasError = !this.match(pattern);
        });
    }

    if (typeof textarea.setCustomValidity === 'function') {
        textarea.setCustomValidity(hasError ? errorMsg : '');
    } else {
        // Not supported by the browser, fallback to manual error display...
        $(textarea).toggleClass('error', !!hasError);
        $(textarea).toggleClass('ok', !hasError);
        if (hasError) {
            $(textarea).attr('title', errorMsg);
        } else {
            $(textarea).removeAttr('title');
        }
    }

    return !hasError;

}

export function toggleChevronAccordion()
{
    $('.collapse').on('shown.bs.collapse', function () {
        $("#"+$(this).attr("aria-labelledby")).find(".fa-chevron-down").removeClass("fa-chevron-down").addClass("fa-chevron-up");
    });

    $('.collapse').on('hidden.bs.collapse', function () {
        $("#"+$(this).attr("aria-labelledby")).find(".fa-chevron-up").removeClass("fa-chevron-up").addClass("fa-chevron-down");
    });
}
