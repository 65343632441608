/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

import './styles/custom.scss';
/******************************* JQUERY *******************************/

const $ = require('jquery') // ou Import $ from 'jquery'
// Provide JQuery everywhere
global.$ = global.jQuery = $

import './js/jquery.validate.js';

import 'jquery-ellipsis';

/******************************* LODASH *******************************/

window._ = require('lodash');

/******************************* BOOTSTRAP *******************************/

import 'bootstrap';
require('bootstrap-icons/font/bootstrap-icons.css');

/******************************* MOMENT *******************************/

window.moment = require('moment');

/******************************* TOOLTIP *******************************/
import { Tooltip, Toast, Popover } from 'bootstrap';
window.tooltip = Tooltip;
window.popover = Popover;
/******************************* POPPER *******************************/


/******************************* JSZIP *******************************/

import * as JSZip from "jszip";
window.JSZip = JSZip;

/******************************* GLOBAL *******************************/

import './js/global.js';

/******************************* CHART *******************************/

import 'chart.js';
//import Chart from 'chart.js';
//import 'chartjs-plugin-colorschemes';

/******************************* DATERANGE *******************************/

import 'daterangepicker';
import 'daterangepicker/daterangepicker.css';

/******************************* FONTAWESOME *******************************/

import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.js';

/*************************** CUSTOM FILE INPUT ***************************/

import bsCustomFileInput from 'bs-custom-file-input';

/******************************* SELECT2 *******************************/

import 'select2';      // globally assign select2 fn to $ element
import 'select2/dist/css/select2.css'; // optional if you have css loader
import 'select2/src/scss/theme/classic/_defaults.scss';

/******************************* SWAGGER-UI *******************************/

import SwaggerUIBundle from 'swagger-ui';
const { presets } = SwaggerUIBundle;
import 'swagger-ui/dist/swagger-ui.css';

/******************************* JS-COOKIE *******************************/

const Cookies = require('js-cookie'); //assign module to variable called "Cookies"
global.Cookies = Cookies;

/******************************* DATEPICKER *******************************/

import 'bootstrap-datepicker';
import './styles/opsportal.css';

// import './dataTable/datatables.min.css';
// import './dataTable/datatables.min.js';

$(document).ready(function() {

    /* SELECT2 */

    $('.select2-enable').select2();
    $('select').addClass('form-select');

    /******************************* MATOMO ANALYTICS *******************************/

    var _paq = window._paq = window._paq || [];

    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);

    (function() {
        var u="//matomo.cc.in2p3.fr/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '6']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();

});

